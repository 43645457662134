import React, { memo } from 'react';
import { pages } from 'config';
import { useActivePage } from 'lib/hooks';

const Home = () => {
  useActivePage(pages.HOME);

  return <p>Home Page</p>;
};

export default memo(Home);
