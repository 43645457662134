import React, { memo } from 'react';
import { Menu } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from '@reach/router';
import { pages } from 'config';
import { useGlobalState } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { signOut } from 'lib/helpers/auth';

const MenuNavigation = ({ mobileNavClick = false }) => {
  const [{ activePage, userRoles }] = useGlobalState();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
  };

  const convertNavsToMenu = (navs) => {
    const menu = [];
    Object.keys(navs).forEach((navKey) => {
      if ('pages' in navs[navKey]) {
        menu.push({
          key: navKey,
          label: navs[navKey].title,
          children: convertNavsToMenu(navs[navKey].pages),
          disabled:
            'roles' in navs[navKey]
              ? !userRoles.roles.some((role) =>
                  navs[navKey].roles.includes(role)
                )
              : false,
        });
      } else {
        menu.push({
          key: navKey,
          label: navs[navKey].title,
          path: navs[navKey].path,
          disabled:
            'roles' in navs[navKey]
              ? !userRoles.roles.some((role) =>
                  navs[navKey].roles.includes(role)
                )
              : false,
        });
      }
    });
    return menu;
  };

  const { LANDING_PAGE, UNEXPECTED_ERROR, ...navs } = pages;

  const menuNavs = [
    ...convertNavsToMenu(navs),
    { type: 'divider' },
    { key: 'signOut', label: 'Sign Out', icon: <LogoutOutlined /> },
  ];

  const menuProps = {
    selectedKeys:
      hasValue(activePage, 'value') && typeof activePage.value !== 'undefined'
        ? [activePage.value]
        : null,
    mode: 'inline',
    items: menuNavs,
  };

  if (mobileNavClick) {
    menuProps.onClick = mobileNavClick;
  }

  const onNavClick = (item) => {
    if (item.key === 'signOut') {
      handleLogout();
    } else {
      const pagedPath = item.keyPath.reverse().reduce((pV, cV, cI, arr) => {
        if (cI + 1 === arr.length) {
          return pV + cV + '.path';
        } else {
          return pV + cV + '.pages.';
        }
      }, '');
      const url = getValue(pages, pagedPath, '/');
      navigate(url);
    }
  };

  return <Menu {...menuProps} onClick={onNavClick} />;
};

export default memo(MenuNavigation);
