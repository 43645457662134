import axios from 'axios';
import { adalApiFetch, getToken } from 'config';

export const adalAdapter = {
  get: async (apiPayload) => {
    const { url } = apiPayload;

    const response = await adalApiFetch(axios, url, {
      Authorization: `Bearer ${getToken()}`,
    });

    return response;
  },
  post: async (apiPayload) => {
    const { url, data } = apiPayload;

    const response = await axios({
      method: 'POST',
      url,
      headers: { Authorization: `Bearer ${getToken()}` },
      data,
    });

    return response;
  },
  put: async (apiPayload) => {
    const { url, data } = apiPayload;

    const response = await axios({
      method: 'PUT',
      url,
      headers: { Authorization: `Bearer ${getToken()}` },
      data,
    });

    return response;
  },
};
