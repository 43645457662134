import React from 'react';
import { Button, Checkbox } from 'antd';
import { navigate } from '@reach/router';

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    showOnEdit: true,
    type: 'number',
    editable: false,
    creatable: false,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Desc',
    dataIndex: 'desc',
    key: 'desc',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Inactive Ind',
    dataIndex: 'inactiveInd',
    key: 'inactiveind',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
];

export const getExtraActions = (row) => [
  <Button onClick={() => navigate(`formType/${row.id}/formTypeElementType`)}>
    ElementTypes
  </Button>,
];
