export const relations = [
  {
    name: 'validationType',
    relatedKey: 'id',
    relatedParentKey: 'validationTypeId',
  },
  {
    attrbName: 'conditionValidationValueType',
    name: 'validationValueType',
    relatedKey: 'id',
    relatedParentKey: 'conditionValidationValueTypeId',
  },
  {
    attrbName: 'conditionValueValidationValueType',
    name: 'validationValueType',
    relatedKey: 'id',
    relatedParentKey: 'conditionValueValidationValueTypeId',
  },
  {
    attrbName: 'valueValidationValueType',
    name: 'validationValueType',
    relatedKey: 'id',
    relatedParentKey: 'valueValidationValueTypeId',
  },
];

export const relatedParentKey = 'elementTypeId';
