import { define } from '@rlean/core';

const ElementType = define('elementType', {
  initialState: {},
  nullableParams: true,
  getURL: '/ElementType',
  postURL: '/ElementType',
});

export default ElementType;
