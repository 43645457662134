export const relations = [
  {
    attrbName: 'defaultSectionType',
    name: 'sectionType',
    relatedKey: 'id',
    relatedParentKey: 'defaultSectionTypeId',
  },
  {
    attrbName: 'outputDataType',
    name: 'dataType',
    relatedKey: 'id',
    relatedParentKey: 'outputDataTypeId',
  },
  {
    name: 'inputControl',
    relatedKey: 'id',
    relatedParentKey: 'inputControlId',
  },
];
