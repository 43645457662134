import React from 'react';
import { Router } from '@reach/router';
import { BaseLayout } from 'components/layout';
import { pages } from 'config';
import Home from 'components/pages/Home';
import { Card } from 'antd';
import SimpleDisplay from 'components/pages/SimpleDisplay';
import ChildDisplay from 'components/pages/ChildDisplay';
import FormData from 'components/pages/FormData';

const Routes = ({ children }) => {
  return (
    <BaseLayout children={children}>
      <Card className='page-card'>
        <Router>
          <Home path={pages.HOME.path} />
          <FormData path={pages.FORM_DATA.path} />
          <SimpleDisplay path='/type/:entity' />
          <ChildDisplay path='/type/:parentEntity/:id/:childEntity' />
        </Router>
      </Card>
    </BaseLayout>
  );
};

export default Routes;
