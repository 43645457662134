/**
 * isAnyStateLoading
 *
 * Checks if any of the states passed as arguments are loading
 */

export function isAnyStateLoading(...stateObjects) {
  for (let i = 0; i < stateObjects.length; i++) {
    const stateObject = stateObjects[i];
    const stateIsLoading =
      !stateObject ||
      (stateObject && typeof stateObject.isLoading === 'undefined') ||
      stateObject.isLoading;
    if (stateIsLoading) {
      return true;
    }
  }
  return false;
}

/**
 * getEntityData
 *
 * Fetchs and returns the data using the get func
 */
export const getEntityData = async (get, entity) => {
  const data = await new Promise((res) => {
    get({ entity }, (resBody) => {
      res(resBody.data);
    });
  });
  return data;
};
