import React, { useEffect, useState } from 'react';
import { useGet, useSave } from '@rlean/core';
import jwtDecode from 'jwt-decode';
import { UserDescription, UserRoles } from 'lib/entities';
import LoadingSpin from 'components/shared/LoadingSpin';
import Unauthorized from 'components/pages/Unauthorized';
import UnexpectedError from 'components/pages/UnexpectedError';

const AUTH_STATUS = {
  LOADING: 'LOADING',
  UNAUTHORIZED: 'UNAUTHORIZED',
  ERROR: 'ERROR',
  OK: 'OK',
};

/**
 * Handle the authorization of the app.
 *
 * The authorization works by trying to get the DataType entity, if this
 * returns a 403 code, then it means that the user is indeed unauthorized.
 */
const Authorizer = (props) => {
  const [status, setStatus] = useState(AUTH_STATUS.LOADING);
  const [get] = useGet();
  const [save] = useSave();

  useEffect(() => {
    /*
     * For future reference:
     * let token = '';
     * try {
     *   token = jwtDecode(
     *     props.authContext.getCachedToken(props.authContext.config.clientId)
     *   );
     *   if (!token) {
     *     // No cached token. Get another one.
     *     token = adalGetToken(authContext, adalConfig.endpoints.api);
     *   }
     * } catch (error) {
     *   console.error(error, { adalGetToken });

     *   // Failed to get token. Get another one.
     *   token = adalGetToken(authContext, adalConfig.endpoints.api);
     * }
     */

    const token = jwtDecode(
      props.authContext.getCachedToken(props.authContext.config.clientId)
    );

    get(
      { entity: UserDescription, params: { oid: token.oid } },
      (res, error) => {
        if (res && !error && res.status === 200) {
          save({ entity: UserRoles, value: { roles: res.data.appRoles } });
          setStatus(AUTH_STATUS.OK);
        } else if (
          !res &&
          error &&
          (error.response.status === 403 || error.response.status === 400)
        ) {
          setStatus(AUTH_STATUS.UNAUTHORIZED);
        } else if (error) {
          setStatus(AUTH_STATUS.ERROR);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (status === AUTH_STATUS.LOADING) {
    return <LoadingSpin />;
  }

  if (status === AUTH_STATUS.UNAUTHORIZED) {
    return <Unauthorized />;
  }

  if (status === AUTH_STATUS.ERROR) {
    return <UnexpectedError />;
  }

  return props.children;
};

export default Authorizer;
