import React, { memo } from 'react';
import { Row, Col } from 'antd';
import { useGlobalState } from '@rlean/core';
import { getValue } from '@rlean/utils';
import MenuDrawer from './Menu/MenuDrawer';

function AppBar() {
  const [{ activePage }] = useGlobalState();

  return (
    <Row className='main-app-bar' type='flex' justify='space-between'>
      <Col className='left'>
        <span className='desktop-only'>
          <strong>ECHO DEVOPS</strong> | {getValue(activePage, 'title')}
        </span>
        <MenuDrawer />
      </Col>
    </Row>
  );
}

export default memo(AppBar);
