import React from 'react';
import { Checkbox } from 'antd';

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    showOnEdit: true,
    type: 'number',
    editable: false,
    creatable: false,
  },
  {
    title: 'Inactive Ind',
    dataIndex: 'inactiveInd',
    key: 'inactiveind',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
  {
    title: 'Element Type Id',
    dataIndex: 'elementType',
    key: 'elementTypeId',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: false,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Element Type Name',
    dataIndex: 'elementType',
    render: (value) => value?.name,
    key: 'elementTypeName',
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Element Type Inactive Ind',
    dataIndex: 'elementType',
    key: 'elementTypeInactiveInd',
    render: (value) => <Checkbox checked={value?.inactiveInd} />,
    showOnEdit: false,
    type: 'boolean',
    editable: false,
    creatable: false,
  },
  {
    title: 'Is Required',
    dataIndex: 'isRequired',
    key: 'isRequired',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
  {
    title: 'Is Quick Form',
    dataIndex: 'isQuickForm',
    key: 'isQuickForm',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
  {
    title: 'Can Edit In Form Builder',
    dataIndex: 'canEditInFormBuilder',
    key: 'canEditInFormBuilder',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
];

export const extraColumns = [];
