import { define } from '@rlean/core';

const ElementTypeValidationType = define('elementTypeValidationType', {
  initialState: {},
  nullableParams: true,
  getURL: '/ElementTypeValidationType',
  postURL: '/ElementTypeValidationType',
});

export default ElementTypeValidationType;
