import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { RLean, StateProvider } from '@rlean/core';
import { runWithAdal } from 'react-adal';
import { authContext, withAdalLoginApi } from 'config/adal';
import { rLean as config } from 'config';
import App from './components/main/App';
import 'antd/dist/antd.css';
import 'assets/main.scss';
import LandingPage from 'components/public/LandingPage';
import { Router } from '@reach/router';
import { pages } from 'config/pages';

RLean.init(config);

const ALLOW_LOGIN = true;

const AuthenticatedApp = withAdalLoginApi(App);

const AuthDirector = () => {
  return (
    <Router>
      <LandingPage path={pages.LANDING_PAGE.path} />

      <AuthenticatedApp path='/*' authContext={authContext} />
    </Router>
  );
};

runWithAdal(
  authContext,
  () => {
    ReactDOM.render(
      <StateProvider>
        <AuthDirector />
      </StateProvider>,
      document.getElementById('root')
    );
  },
  ALLOW_LOGIN
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
