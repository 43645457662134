import React, { memo, useEffect, useState } from 'react';
import DataDisplay from 'components/shared/DataDisplay';
import { useParams } from '@reach/router';
import { useGlobalState, useGet } from '@rlean/core';
import { hasValue } from '@rlean/utils';
import { Checkbox, Descriptions } from 'antd';
import { capitalizeStr } from 'lib/helpers/stringManipulation';
import { pages } from 'config';
import { navigate } from '@reach/router';

/**
 * ChildDisplay
 *
 * Gets the related information of the child entity (config, columns, relations)
 * to preset the data with the DataDisplay component and of the parent to show
 * the parent's data.
 */
const ChildDisplay = () => {
  const { parentEntity, id, childEntity } = useParams();

  const [{ [parentEntity]: parentState, userRoles }] = useGlobalState();
  const [get] = useGet();
  const [parent, setParent] = useState(null);

  const parentNameCap = capitalizeStr(parentEntity);
  const childNameCap = capitalizeStr(childEntity);

  // Get child's related config
  const childEntityConfig = require(`lib/entities/${childNameCap}`).default;
  const {
    columns,
    extraColumns,
  } = require(`./Entities/${parentNameCap}/${childNameCap}/columns`);
  let childRelations = [];
  let childRelatedParentKey = '';

  // Catches the error in case there's no relations for the entity
  try {
    const {
      relations,
      relatedParentKey,
    } = require(`./Entities/${parentNameCap}/${childNameCap}/relations`);
    childRelations = relations;
    childRelatedParentKey = relatedParentKey;
  } catch (e) {
    console.log("SimpleDisplay: Relation doesn't exist for this entity");
  }

  useEffect(() => {
    if (
      pages.TYPE_PAGES.roles &&
      !userRoles.roles.some((role) => pages.TYPE_PAGES.roles.includes(role))
    ) {
      navigate('/home');
    }
  }, []);

  // Get's the parent state data
  useEffect(() => {
    if (!hasValue(parentState, 'data')) {
      const parentEntityConfig =
        require(`lib/entities/${parentNameCap}`).default;
      get({ entity: parentEntityConfig });
    }
  }, [parentEntity, parentState, parentNameCap, get]);

  // Get the parent object for this child
  useEffect(() => {
    if (hasValue(parentState, 'data')) {
      const baseParent = parentState.data.find(
        (item) => String(item.id) === String(id)
      );
      if (baseParent) {
        setParent(baseParent);
      }
    }
  }, [parentState, id]);

  return (
    <>
      <Descriptions
        title={`Details of parent ${parentEntity}`}
        bordered
        column={1}
        style={{ marginBottom: '15px' }}
      >
        <Descriptions.Item label='Id'>{parent?.id}</Descriptions.Item>
        <Descriptions.Item label='name'>{parent?.name}</Descriptions.Item>
        <Descriptions.Item label='inactiveInd'>
          <Checkbox checked={parent?.inactiveInd} />
        </Descriptions.Item>
      </Descriptions>

      <DataDisplay
        entity={childEntityConfig}
        columns={columns}
        extraColumns={extraColumns}
        relations={childRelations}
        filter={{
          relatedKey: childRelatedParentKey,
          relatedParentKeyValue: id,
        }}
      />
    </>
  );
};

export default memo(ChildDisplay);
