import { define } from '@rlean/core';

const FormTypeElementType = define('formTypeElementType', {
  initialState: {},
  nullableParams: true,
  getURL: '/FormTypeElementType',
  postURL: '/FormTypeElementType',
});

export default FormTypeElementType;
