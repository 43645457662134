import { define } from '@rlean/core';

const ElementTypeElementAttributeType = define('elementTypeElementAttributeType', {
  initialState: {},
  nullableParams: true,
  getURL: '/ElementTypeElementAttributeType',
  postURL: '/ElementTypeElementAttributeType',
});

export default ElementTypeElementAttributeType;
