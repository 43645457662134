import React from 'react';
import { Checkbox } from 'antd';

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    showOnEdit: true,
    type: 'number',
    editable: false,
    creatable: false,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    showOnEdit: true,
    type: 'string',
    editable: false,
    creatable: true,
  },
  {
    title: 'Inactive Ind',
    dataIndex: 'inactiveInd',
    key: 'inactiveind',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
];

export const extraColumns = [];
