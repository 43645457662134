import React, { memo } from 'react';
import { Layout } from 'antd';
import AppBar from './AppBar';
import MenuLayout from './Menu/MenuLayout';

const BaseLayout = ({ children }) => {
  const { Sider, Header, Content } = Layout;
  return (
    <Layout>
      <Sider
        className='main-sider'
        breakpoint='xl'
        collapsedWidth='0'
        style={{ zIndex: 100 }}
      >
        <MenuLayout />
      </Sider>
      <Layout className='main-section'>
        <Header className='main-header' style={{ zIndex: 100 }}>
          <AppBar />
        </Header>
        <Content className='main-content'>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default memo(BaseLayout);
