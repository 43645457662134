import React, { memo } from 'react';
import Routes from './Routes';
import Authorizer from './Authorizer';

const App = (props) => {
  return (
    <div className='App'>
      <Authorizer authContext={props.authContext}>
        <Routes />
      </Authorizer>
    </div>
  );
};

export default memo(App);
