import { define } from '@rlean/core';

const FormData = define('formData', {
  initialState: [],
  nullableParams: true,
  getURL: '/FormData',
  postURL: '/FormData',
});

export default FormData;
