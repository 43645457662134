import { define } from '@rlean/core';

const ElementAttributeType = define('elementAttributeType', {
  initialState: {},
  nullableParams: true,
  getURL: '/ElementAttributeType',
  postURL: '/ElementAttributeType',
});

export default ElementAttributeType;
