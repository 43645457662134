import React, { memo, useEffect, useState } from 'react';
import { Button, Space, Table } from 'antd';
import { useGet, useGlobalState } from '@rlean/core';
import { deepCopy, hasValue, getValue } from '@rlean/utils';
import { navigate } from '@reach/router';
import { pages } from 'config';
import EditorModal, { FORM_MODAL_MODE } from './EditorModal';
import { capitalizeStr } from 'lib/helpers/stringManipulation';
import { getEntityData } from 'lib/helpers/stateDataHelpers';

/**
 * DataDisplay
 *
 * Presents the data in a table and mounts the editor/creator modal
 *
 * @param {Object} entity Entity config object
 * @param {Array} columns The columns/attributes of the entity. Used
 *     also for the editor/creator form
 * @param {Function} getExtraActions Extra actions for the entity
 * @param {Array} relations The relations for the entity, used to attach
 *     the relations to the entity
 * @param {Object} filter Contains the information of the parent: its
 *     id and the attributename of the child that relates to the parent.
 */
const DataDisplay = ({
  entity,
  columns,
  getExtraActions = null,
  relations = [],
  filter = undefined,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [get] = useGet();
  const [isEditorVisible, setIsEditorVisible] = useState(false);
  const [entityIdBeingEdited, setEntityIdBeingEdited] = useState(null);
  const [modalMode, setModalMode] = useState(FORM_MODAL_MODE.EDIT);
  const [globalState] = useGlobalState();

  // Find the related data to the parent entity
  const getCachedRelatedData = (
    childName,
    relatedKey,
    relatedParentKeyValue
  ) => {
    return getValue(globalState, `${childName}.data`, []).find(
      (childItem) => childItem[relatedKey] === relatedParentKeyValue
    );
  };

  // Attach the child entities to the parent entity
  const relateEntities = (relations, parentData) => {
    // The relation contains information about how the child is related to the
    // parent entity. Using each relation, we go through all the items in the
    // parent entity and find the respective child data. Notice that this
    // is all 1-1 relationships.

    relations.forEach((relation) => {
      parentData.forEach((_, idx, thisArr) => {
        const { attrbName, name, relatedKey, relatedParentKey } = relation;

        thisArr[idx][attrbName ? attrbName : name] = getCachedRelatedData(
          name,
          relatedKey,
          thisArr[idx][relatedParentKey]
        );
      });
    });
  };

  // Save the related data
  // @todo handle errors
  const getRelationData = (relation) => {
    return new Promise(async (res, rej) => {
      const { name } = relation;

      const capModalName = capitalizeStr(name);
      const childModal = require(`lib/entities/${capModalName}`).default;

      if (!hasValue(globalState, `${relation.name}.data`)) {
        await getEntityData(get, childModal);
      }

      res();
    });
  };

  // For all the relations, get their data
  const getRelationsData = (relations) => {
    return Promise.all(relations.map((relation) => getRelationData(relation)));
  };

  // Pull the entity's data, its child data and relate them
  const refreshData = () => {
    setLoading(true);

    get({ entity: entity }, async ({ data, error }) => {
      if (error) {
        navigate(pages.UNEXPECTED_ERROR.path);
      } else if (data) {
        // apply filters
        let baseData = deepCopy(data);

        if (filter) {
          baseData = baseData.filter(
            (item) =>
              String(item[filter.relatedKey]) ===
              String(filter.relatedParentKeyValue)
          );
        }

        // relate child data to parent
        await getRelationsData(relations);
        relateEntities(relations, baseData);

        // sort by Id
        baseData.sort((a, b) => a.id - b.id);

        // add keys to the data
        baseData.forEach((value, idx, thisArr) => {
          thisArr[idx].key = `_data_${entity.key}_${value.id}`;
        });

        // save Data
        setData(baseData);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    refreshData();

    return () => {
      setEntityIdBeingEdited(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const edit = (id) => {
    setIsEditorVisible(true);
    setEntityIdBeingEdited(id);
    setModalMode(FORM_MODAL_MODE.EDIT);
  };

  const create = () => {
    setIsEditorVisible(true);
    setModalMode(FORM_MODAL_MODE.CREATE);
  };

  const closeEdit = () => {
    setIsEditorVisible(false);
  };

  const actions = [
    {
      title: 'Action',
      key: 'operations',
      fixed: 'right',
      display: false,
      width: 100,
      render: (_, record) => (
        <div className='actionButtonsContainer'>
          <Button
            onClick={() => edit(record.id)}
            disabled={process.env.REACT_APP_READ_ONLY === 'true'}
          >
            Edit
          </Button>
          {getExtraActions && getExtraActions(record)}
        </div>
      ),
    },
  ];

  return (
    <>
      <Space direction='horizontal' style={{ marginBottom: '15px' }}>
        <h1>List of {entity.key}s</h1>
        <Button
          onClick={create}
          disabled={process.env.REACT_APP_READ_ONLY === 'true'}
        >
          Add new
        </Button>
      </Space>
      <div style={{ overflowX: 'scroll', marginBottom: '15px' }}>
        <Table
          bordered
          size='small'
          dataSource={data}
          columns={[...columns, ...actions]}
          loading={loading}
          pagination={{ position: ['bottomLeft'], pageSize: 100 }}
        />
      </div>
      <EditorModal
        mode={modalMode}
        isVisible={isEditorVisible}
        onClose={closeEdit}
        columns={columns}
        relations={relations}
        entity={entity}
        entityId={entityIdBeingEdited}
        childForeingKeyAndValue={filter}
        refreshData={refreshData}
      />
    </>
  );
};

export default memo(DataDisplay);
