import { define } from '@rlean/core';

const InputControl = define('inputControl', {
  initialState: {},
  nullableParams: true,
  getURL: '/InputControl',
  postURL: '/InputControl',
});

export default InputControl;
