import { define } from '@rlean/core';

const AutoGenerateAlgorithm = define('autoGenerateAlgorithm', {
  initialState: {},
  nullableParams: true,
  getURL: '/AutoGenerateAlgorithm',
  postURL: '/AutoGenerateAlgorithm',
});

export default AutoGenerateAlgorithm;
