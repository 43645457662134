import { define } from '@rlean/core';

const ValidationType = define('validationType', {
  initialState: {},
  nullableParams: true,
  getURL: '/ValidationType',
  postURL: '/ValidationType',
});

export default ValidationType;
