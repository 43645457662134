import { define } from '@rlean/core';

const DataType = define('dataType', {
  initialState: {},
  nullableParams: true,
  getURL: '/DataType',
  postURL: '/DataType',
});

export default DataType;
