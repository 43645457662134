import React, { memo, useEffect } from 'react';
import { Layout, Button, Row, Col } from 'antd';
import { navigate, Link } from '@reach/router';
import { authContext } from 'config/adal';
import logoWhite from 'assets/SQ-logo-white.png';
import { pages } from 'config/pages';

const LandingPage = () => {
  const { Header, Content } = Layout;

  useEffect(() => {
    if (authContext._user) {
      navigate(pages.HOME.path);
    }
  }, []);

  const handleSignIn = () => {
    navigate(pages.HOME.path);
  };

  return (
    <Layout className='landing-section'>
      <Header className='landing-header'>
        <Row className='landing-app-bar' justify='space-between'>
          <Col span={8} className='left'>
            <span className='desktop-only'>
              <strong>ECHO DEVOPS</strong>
            </span>
          </Col>
          <Col span={8} className='middle'>
            <Link to='/'>
              <img src={logoWhite} alt='Stratosphere Quality logo' />
            </Link>
          </Col>
          <Col span={8} className='right'>
            <Button
              className='desktop-only'
              type='primary'
              onClick={handleSignIn}
              style={{ margin: '0 10px' }}
            >
              Sign In
            </Button>
          </Col>
        </Row>
      </Header>
      <Content className='landing-content'>
        <Row justify='center' align='middle' style={{ height: '100%' }}>
          <Col span={12} className='landing-call-to-action'>
            <h1 className='landing-message'>Echo DevOps</h1>
            <Button
              type='primary'
              onClick={(e) => handleSignIn(e)}
              size='large'
            >
              Sign In
            </Button>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default memo(LandingPage);
