import React, { memo } from 'react';
import { Layout, Row, Card } from 'antd';
import MenuNavigation from './MenuNavigation';
import logoColor from 'assets/SQ-logo-color.png';

const MenuLayout = ({ mobileNavClick }) => {
  const { Header, Content, Footer } = Layout;

  return (
    <Layout>
      <Header>
        <img
          style={{ width: '70%' }}
          src={logoColor}
          alt='Stratosphere Quality logo'
        />
      </Header>
      <Content>
        <MenuNavigation mobileNavClick={mobileNavClick} />
      </Content>
      <Footer>
        <Card>
          <Row className='copyright'>
            © Stratosphere Quality {new Date().getFullYear()} All rights
            reserved.
          </Row>
        </Card>
      </Footer>
    </Layout>
  );
};

export default memo(MenuLayout);
