import { define } from '@rlean/core';

const SectionType = define('sectionType', {
  initialState: {},
  nullableParams: true,
  getURL: '/SectionType',
  postURL: '/SectionType',
});

export default SectionType;
