import { adalAdapter } from 'lib/adapters/AdalAdapter';
import * as entities from 'lib/entities';
import { getToken } from './adal';

export const rLean = {
  entities,
  utilities: null,
  api: {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    baseURL: process.env.REACT_APP_API_URI,
    adapter: adalAdapter,
  },
  logToConsole:
    process.env.REACT_APP_ENV === 'local' ||
    process.env.REACT_APP_ENV === 'dev',
};
