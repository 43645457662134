import React, { memo, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Switch,
  Button,
  Space,
  message,
  Select,
  InputNumber,
} from 'antd';
import { useGlobalState, usePost, useGet } from '@rlean/core';
import { hasValue, getValue } from '@rlean/utils';
import { capitalizeStr } from 'lib/helpers/stringManipulation';
import { getEntityData } from 'lib/helpers/stateDataHelpers';

export const FORM_MODAL_MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

/**
 * EditorModal
 *
 * Builds a form to edit or create an item from the entity
 *
 * @param {FORM_MODAL_MODE} mode Either to edit or create a new item
 * @param {boolean} isVisible Show/hide modal
 * @param {Function} onClose Callback to close the modal
 * @param {Array} columns The columns/attributes of the entity, used to
 *     build the form
 * @param {Object} relations The relations for the entity, used to build
 *     a select, for 1-1 relationships
 * @param {Object} entity Entity config object
 * @param {string|number} entityId The id of the object being edited
 * @param {Object} childForeingKeyAndValue The parent id and the attribute
 *     name of the child that relates to the parent
 * @param {Function} refreshData callback after editing/creating
 */
const EditorModal = ({
  mode = FORM_MODAL_MODE.EDIT,
  isVisible,
  onClose,
  columns,
  relations,
  entity,
  entityId,
  childForeingKeyAndValue = undefined,
  refreshData = () => {},
}) => {
  const [form] = Form.useForm();
  const [post] = usePost();
  const [get] = useGet();
  const [globalState] = useGlobalState();

  const onSave = (values) => {
    let body = values;

    if (childForeingKeyAndValue) {
      // Add the parent key value (this information comes from the filter)
      body = {
        ...values,
        [childForeingKeyAndValue.relatedKey]: isNaN(
          childForeingKeyAndValue.relatedParentKeyValue
        )
          ? childForeingKeyAndValue.relatedParentKeyValue
          : Number(childForeingKeyAndValue.relatedParentKeyValue),
      };
    }

    message.destroy();
    message.loading('Saving...');

    post({ entity: entity, body }, (_, error) => {
      message.destroy();
      if (error) {
        message.error(`There has been an error, please try again`);
      } else {
        message.success('Done!');
        refreshData();
        onClose();
      }
      form.resetFields();
    });
  };

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  // Save the related data
  // @todo handle errors
  const getRelationData = (relation) => {
    return new Promise(async (res, rej) => {
      const { name } = relation;

      const capModalName = capitalizeStr(name);
      const childModal = require(`lib/entities/${capModalName}`).default;

      if (!hasValue(globalState, `${relation.name}.data`)) {
        await getEntityData(get, childModal);
      }

      res();
    });
  };

  // For all the relations, get and attach their own child
  const getRelationsData = async (relations) => {
    return Promise.all(relations.map((relation) => getRelationData(relation)));
  };

  const getFormItemForColumn = (column, idx) => {
    let inputItem = null;
    let dataIndex = column.dataIndex;
    if (column.isForeignKey) {
      const relation = relations.find((relation) =>
        relation.attrbName
          ? relation.attrbName === column.dataIndex
          : relation.name === column.dataIndex
      );

      if (!relation) {
        console.error("There's no relation for this entity", column.dataIndex);
      } else {
        dataIndex = relation.relatedParentKey;
        inputItem = (
          <Select disabled={mode === FORM_MODAL_MODE.EDIT && !column.editable}>
            {getValue(globalState, `${relation.name}.data`, [])
              .sort((a, b) => a.id - b.id)
              .map((item) => (
                <Select.Option
                  value={item[relation.relatedKey]}
                  key={`_select_${item.id}`}
                >
                  {item.id} -- {item.name} --{' '}
                  {item.inactiveInd ? 'Inactive' : 'Active'}
                </Select.Option>
              ))}
          </Select>
        );
      }
    } else {
      switch (column.type) {
        case 'number':
          if (mode === FORM_MODAL_MODE.EDIT) {
            inputItem = <InputNumber disabled={!column.editable} />;
          } else {
            inputItem = <InputNumber />;
          }
          break;
        case 'string':
          if (mode === FORM_MODAL_MODE.EDIT) {
            inputItem = <Input disabled={!column.editable} />;
          } else {
            inputItem = <Input />;
          }
          break;
        case 'boolean':
          if (mode === FORM_MODAL_MODE.EDIT) {
            inputItem = <Switch disabled={!column.editable} />;
          } else {
            inputItem = <Switch />;
          }
          break;
        default:
          break;
      }
    }

    return (
      <Form.Item
        name={dataIndex}
        label={column.title}
        labelCol={{ span: 9 }}
        wrapperCol={{ offset: 1 }}
        valuePropName={column.type === 'boolean' ? 'checked' : undefined}
        key={`modal_input_${column.key}_${idx}`}
      >
        {inputItem}
      </Form.Item>
    );
  };

  const filterFormItems = (formItem) => {
    if (mode === FORM_MODAL_MODE.EDIT) {
      return formItem.showOnEdit;
    } else {
      return formItem.creatable;
    }
  };

  // Reset fields if entering in another mode
  useEffect(() => {
    if (mode === FORM_MODAL_MODE.CREATE) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // If editing get previous values
  useEffect(() => {
    if (
      mode === FORM_MODAL_MODE.EDIT &&
      hasValue(globalState[entity.key], 'data')
    ) {
      const data = globalState[entity.key].data.find(
        (item) => String(item.id) === String(entityId)
      );
      form.setFieldsValue(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState[entity.key], entityId]);

  // Get all related data
  useEffect(() => {
    async function getData() {
      await getRelationsData(relations);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relations]);

  const filteredColumnsByMode = columns.filter(filterFormItems);

  return (
    <Modal visible={isVisible} onCancel={onClose} footer={null}>
      Add or Edit
      <Form form={form} onFinish={onSave}>
        {filteredColumnsByMode.length === 0 &&
        mode === FORM_MODAL_MODE.CREATE ? (
          <p>Cannot create</p>
        ) : (
          filteredColumnsByMode.map(getFormItemForColumn)
        )}
        <Space direction='horizontal'>
          <Form.Item>
            <Button type='default' onClick={handleClose}>
              Cancel
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              disabled={filteredColumnsByMode.length === 0}
            >
              Save
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default memo(EditorModal);
