import React, { memo, useEffect } from 'react';
import { useParams } from '@reach/router';
import { capitalizeStr } from 'lib/helpers/stringManipulation';
import DataDisplay from 'components/shared/DataDisplay';
import { useActivePage } from 'lib/hooks';
import { pages } from 'config';
import { navigate } from '@reach/router';
import { useGlobalState } from '@rlean/core';

/**
 * SimpleDisplay
 *
 * Gets the related information of the entity (config, columns, relations)
 * and mounts the DataDisplay component.
 */
const SimpleDisplay = () => {
  const [{ userRoles }] = useGlobalState();
  let { entity } = useParams();

  useActivePage(
    pages.TYPE_PAGES.pages[entity.replace(/([A-Z])/g, '_$1').toUpperCase()]
  );

  useEffect(() => {
    if (
      pages.TYPE_PAGES.roles &&
      !userRoles.roles.some((role) => pages.TYPE_PAGES.roles.includes(role))
    ) {
      navigate('/home');
    }
  }, []);

  // Get entity's configs
  entity = capitalizeStr(entity);
  const entityObj = require(`lib/entities/${entity}`).default;
  const { columns, getExtraActions } = require(`./Entities/${entity}/columns`);

  let relations = [];
  try {
    relations = require(`./Entities/${entity}/relations`).relations;
  } catch (e) {
    // If there's no relation, then there's no issue - unless there's
    // related data in the columns file
    // console.info("SimpleDisplay: Relation doesn't exist for this entity");
  }

  return (
    <DataDisplay
      entity={entityObj}
      columns={columns}
      getExtraActions={getExtraActions}
      relations={relations}
    />
  );
};

export default memo(SimpleDisplay);
