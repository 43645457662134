import { define } from '@rlean/core';

const ValidationValueType = define('validationValueType', {
  initialState: {},
  nullableParams: true,
  getURL: '/ValidationValueType',
  postURL: '/ValidationValueType',
});

export default ValidationValueType;
