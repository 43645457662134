import React from 'react';
import { Checkbox } from 'antd';

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    showOnEdit: true,
    type: 'number',
    editable: false,
    creatable: false,
  },
  {
    title: 'Inactive Ind',
    dataIndex: 'inactiveInd',
    key: 'inactiveind',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
  {
    title: 'Default Message',
    dataIndex: 'defaultMessage',
    key: 'defaultMessage',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Validation Type Id',
    dataIndex: 'validationType',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Validation Type Name',
    dataIndex: 'validationType',
    key: 'validationTypeName',
    render: (value) => value?.name,
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Validation Type Inactive Ind',
    dataIndex: 'validationType',
    key: 'validationTypeInactiveInd',
    render: (value) => <Checkbox checked={value.inactiveInd} />,
    showOnEdit: false,
    type: 'boolean',
    editable: true,
    creatable: false,
  },
  {
    title: 'Default Value',
    dataIndex: 'defaultValue',
    key: 'defaultValue',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Value Type Id',
    dataIndex: 'valueValidationValueType',
    key: 'valueValidationValueTypeId',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Value Type Name',
    dataIndex: 'valueValidationValueType',
    key: 'valueValidationValueType',
    render: (value) => value?.name,
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Default Condition',
    dataIndex: 'defaultCondition',
    key: 'defaultCondition',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Condition Type Id',
    dataIndex: 'conditionValidationValueType',
    key: 'conditionValidationValueTypeId',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Condition Type Name',
    dataIndex: 'conditionValidationValueType',
    key: 'conditionValidationValueTypeName',
    render: (value) => value?.name,
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Default Condition Operator',
    dataIndex: 'defaultConditionOperator',
    key: 'defaultConditionOperator',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Default Condition Value',
    dataIndex: 'defaultConditionValue',
    key: 'defaultConditionValue',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Condition Value Type Id',
    dataIndex: 'conditionValueValidationValueType',
    key: 'conditionValueValidationValueTypeId',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Condition Value Type Name',
    dataIndex: 'conditionValueValidationValueType',
    key: 'conditionValueValidationValueTypeName',
    render: (value) => value?.name,
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Can Edit In Form Builder',
    dataIndex: 'canEditInFormBuilder',
    key: 'canEditInFormBuilder',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
];

export const extraColumns = [];
