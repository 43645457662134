import jwtDecode from 'jwt-decode';
import { adalGetToken } from 'react-adal';
import { adalConfig, authContext } from 'config/adal';
import { removeAll } from '@rlean/core';

export const getAuthToken = () => {
  let token = '';
  try {
    token = jwtDecode(authContext.getCachedToken(authContext.config.clientId));

    if (!token) {
      token = adalGetToken(authContext, adalConfig.endpoints.api);
    }
  } catch (e) {
    token = adalGetToken(authContext, adalConfig.endpoints.api);
    console.error("Couldn't get token:", e);
  }

  return token;
};

export const signOut = () => {
  removeAll();
  authContext.logOut();
};
