import { define } from '@rlean/core';

const FormType = define('formType', {
  initialState: {},
  nullableParams: true,
  getURL: '/FormType',
  postURL: '/FormType',
});

export default FormType;
