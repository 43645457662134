export const pages = {
  LANDING_PAGE: {
    key: 'LANDING_PAGE',
    title: 'Main',
    path: '/',
  },
  HOME: {
    key: 'HOME',
    title: 'Home',
    path: '/home',
  },
  UNEXPECTED_ERROR: {
    key: 'UNEXPECTED_ERROR',
    title: 'Unexpected Error',
    path: '/unexpected-error',
  },
  FORM_DATA: {
    roles: ['DevOps', 'Support'],
    key: 'FORM_DATA',
    title: 'Form Data',
    path: '/formData',
  },
  TYPE_PAGES: {
    roles: ['DevOps'],
    title: 'Form Definitions',
    pages: {
      FORM_TYPE: {
        key: 'FORM_TYPE',
        title: 'Form Type',
        path: '/type/formType',
      },
      ELEMENT_TYPE: {
        key: 'ELEMENT_TYPE',
        title: 'Element Type',
        path: '/type/elementType',
      },
      AUTO_GENERATE_ALGORITHM: {
        key: 'AUTO_GENERATE_ALGORITHM',
        title: 'Auto Generate Algorithm',
        path: '/type/autoGenerateAlgorithm',
      },
      DATA_TYPE: {
        key: 'DATA_TYPE',
        title: 'Data type',
        path: '/type/dataType',
      },
      ELEMENT_ATTRIBUTE_TYPE: {
        key: 'ELEMENT_ATTRIBUTE_TYPE',
        title: 'Element Attribute Type',
        path: '/type/elementAttributeType',
      },
      INPUT_CONTROL: {
        key: 'INPUT_CONTROL',
        title: 'Input Control',
        path: '/type/inputControl',
      },
      SECTION_TYPE: {
        key: 'SECTION_TYPE',
        title: 'Section Type',
        path: '/type/sectionType',
      },
      VALIDATION_TYPE: {
        key: 'VALIDATION_TYPE',
        title: 'Validation type',
        path: '/type/validationType',
      },
      VALIDATION_VALUE_TYPE: {
        key: 'VALIDATION_VALUE_TYPE',
        title: 'Validation Value Type',
        path: '/type/validationValueType',
      },
    },
  },
};
