import React from 'react';
import { Button, Checkbox } from 'antd';
import { navigate } from '@reach/router';

export const columns = [
  {
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    showOnEdit: true,
    type: 'number',
    editable: false,
    creatable: false,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Default Label',
    dataIndex: 'defaultLabel',
    key: 'defaultLabel',
    showOnEdit: true,
    type: 'string',
    editable: true,
    creatable: true,
  },
  {
    title: 'Output Data Type Id',
    dataIndex: 'outputDataType',
    key: 'outputDataTypeId',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Output Data Type Name',
    dataIndex: 'outputDataType',
    key: 'OutputDataTypeName',
    render: (value) => (value && value.name ? value.name : '--'),
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Is SOLAR Data',
    dataIndex: 'isSolarData',
    key: 'isSolarData',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
  {
    title: 'Input Control Id',
    dataIndex: 'inputControl',
    key: 'inputControlId',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Input Control Name',
    dataIndex: 'inputControl',
    key: 'InputControlName',
    render: (value) => (value && value.name ? value.name : '--'),
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Default Display Order',
    dataIndex: 'defaultDisplayOrder',
    key: 'defaultDisplayOrder',
    sorter: (a, b) =>
      Number(a.defaultDisplayOrder) - Number(b.defaultDisplayOrder),
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
  },
  {
    title: 'Default Section Type Id',
    dataIndex: 'defaultSectionType',
    key: 'defaultSectionTypeId',
    render: (value) => value?.id,
    showOnEdit: true,
    type: 'number',
    editable: true,
    creatable: true,
    isForeignKey: true,
  },
  {
    title: 'Default Section Type Name',
    dataIndex: 'defaultSectionType',
    key: 'defaultSectionTypeName',
    render: (value) => (value && value.name ? value.name : '--'),
    showOnEdit: false,
    type: 'string',
    editable: false,
    creatable: false,
  },
  {
    title: 'Inactive Ind',
    dataIndex: 'inactiveInd',
    key: 'inactiveind',
    render: (value) => <Checkbox checked={value} />,
    showOnEdit: true,
    type: 'boolean',
    editable: true,
    creatable: true,
  },
];

export const getExtraActions = (row) => [
  <Button
    onClick={() => navigate(`elementType/${row.id}/elementTypeValidationType`)}
  >
    ValidationTypes
  </Button>,
  <Button
    onClick={() =>
      navigate(`elementType/${row.id}/elementTypeElementAttributeType`)
    }
  >
    ElementAttributeTypes
  </Button>,
];
